import '@georapbox/a-tab-group/dist/a-tab-group.js';
import '@georapbox/web-share-element/dist/web-share-defined.js';
import '@georapbox/files-dropzone-element/dist/files-dropzone-defined.js';
import { isWebShareSupported }                              from '@georapbox/web-share-element/dist/is-web-share-supported.js';
import '@georapbox/resize-observer-element/dist/resize-observer-defined.js';
import { CapturePhoto }                                     from '@georapbox/capture-photo-element/dist/capture-photo.js';
import { getHistory, setHistory, getSettings, setSettings } from './services/storage.js';
import { toastAlert }                                       from './toast-alert.js';
import { debounce }                                         from './utils/debounce.js';
import './custom-clipboard-copy.js';

(async function () {
  const NO_BARCODE_DETECTED = 'No barcode detected';
  const tabGroupEl          = document.querySelector('a-tab-group');
  const cameraPanel         = document.getElementById('cameraPanel');
  const carTabSpan              = document.getElementById('carTab').getElementsByTagName('span')[0];
  const capturePhotoEl      = document.querySelector('capture-photo');
  const cameraResultsEl     = document.getElementById('cameraResults');
  const scanInstructionsEl  = document.getElementById('scanInstructions');
  const scanBtn             = document.getElementById('scanBtn');
  const resizeObserverEl    = document.querySelector('resize-observer');
  const scanFrameEl         = document.getElementById('scanFrame');
  const globalActionsEl     = document.getElementById('globalActions');
  const historyBtn          = document.getElementById('historyBtn');
  const historyDialog       = document.getElementById('historyDialog');
  const historyList         = document.getElementById('historyList');
  const deleteHistoryBtn    = document.getElementById('deleteHistoryBtn');
  const settingsBtn         = document.getElementById('settingsBtn');
  const settingsDialog      = document.getElementById('settingsDialog');
  const settingsForm        = document.forms['settings-form'];
  const nextBtn             = document.getElementById('nextBtn');
  const carContainer        = document.querySelector('.car-container');
  const wheels              = document.querySelectorAll('.wheel-input');
  const startNumber         = document.querySelector('#startnumber');
  const apiBase             = location.origin.replace('1234', '4010') + '/api';

  let scanTimeout = null;

  let teamData = {
    startNumber: 0,
    driverName: '',
    nat: '',
    teamName: '',
    vehicle: '',
    side: '', // right or left
    location: '' // front or rear
  };

  let activeWheel = '';

  let shouldRepeatScan = false;
  let rafId;

  if (!('BarcodeDetector' in window)) {
    try {
      await import('barcode-detector');
      log('Using BarcodeDetector polyfill.');
    }
    catch (err) {
      globalActionsEl.hidden   = true;
      tabGroupEl.style.display = 'none';
      return toastAlert('BarcodeDetector API is not supported by your browser.', 'danger');
    }
  } else {
    log('Using the native BarcodeDetector API.');
  }

  if (!isWebShareSupported()) {
    document.querySelectorAll('web-share').forEach(el => {
      el.hidden   = true;
      el.disabled = true;
    });
  }

  const { value: history = [] } = await getHistory();

  renderHistoryList(history);

  capturePhotoEl.addEventListener('capture-photo:video-play', evt => {
    scanFrameEl.hidden = false;
    resizeScanFrame(evt.detail.video);
    //scan();

    const trackSettings     = capturePhotoEl.getTrackSettings();
    const trackCapabilities = capturePhotoEl.getTrackCapabilities();
    const zoomLevelEl       = document.getElementById('zoomLevel');

    if (trackSettings?.zoom && trackCapabilities?.zoom) {
      const zoomControls = document.getElementById('zoomControls');
      const minZoom      = trackCapabilities?.zoom?.min || 0;
      const maxZoom      = trackCapabilities?.zoom?.max || 10;
      let currentZoom    = trackSettings?.zoom || 1;

      zoomControls.hidden     = false;
      zoomLevelEl.textContent = currentZoom;

      zoomControls.addEventListener('click', evt => {
        const zoomInBtn  = evt.target.closest('[data-action="zoom-in"]');
        const zoomOutBtn = evt.target.closest('[data-action="zoom-out"]');

        if (zoomInBtn && currentZoom < maxZoom) {
          currentZoom += 0.5;
        }

        if (zoomOutBtn && currentZoom > minZoom) {
          currentZoom -= 0.5;
        }

        zoomLevelEl.textContent = currentZoom;

        capturePhotoEl.zoom = currentZoom;
      });
    }
  }, {
    once: true
  });

  capturePhotoEl.addEventListener('capture-photo:error', evt => {
    const error = evt.detail.error;

    if (error.name === 'NotFoundError') {
      // If the browser cannot find all media tracks with the specified types that meet the constraints given.
      return;
    }

    const errorMessage = error.name === 'NotAllowedError'
      ? 'Permission to use webcam was denied or video Autoplay is disabled. Reload the page to give appropriate permissions to webcam.'
      : error.message;

    cameraPanel.innerHTML = /* html */`<div class="alert alert-danger" role="alert" style="margin: 0;">${ errorMessage }</div>`;
  }, {
    once: true
  });

  CapturePhoto.defineCustomElement();

  const capturePhotoVideoEl      = capturePhotoEl.shadowRoot.querySelector('video');
  const formats                  = await window.BarcodeDetector.getSupportedFormats();
  const barcodeDetector          = new window.BarcodeDetector({ formats });
  const { value: settings = {} } = await getSettings();

  Object.entries(settings).forEach(([key, value]) => {
    const settingInput = settingsForm.querySelector(`[name="${ key }"]`);
    if (settingInput) {
      settingInput.checked = value;
    }
  });

  if (Array.isArray(formats) && formats.length > 0) {
    log(`Supported formats: ${ formats.join(', ') }`);
  }

  const resetView = (text, failure = true) => {
    cameraPanel.parentElement.children[0].click();

    emptyResults(cameraResultsEl);
    cameraResultsEl.close();

    wheels.forEach(item => { item.classList.remove('is-active'); });

    if( activeWheel ) {
      carTabSpan.innerHTML = 'Click & Scan next wheel'
    }else if (startNumber.innerHTML !== 'X'){
      carTabSpan.innerHTML = 'Click & Scan the first wheel'
    }else{
      carTabSpan.innerHTML = 'Click on car to start'
    }

    if( !failure ){
      clearTimeout(scanTimeout);
    }
  };

  const openScanView = (text) => {
    cameraPanel.parentElement.children[2].click();

    if( activeWheel ) {
      carTabSpan.innerHTML = 'Scan wheel barcode'
    }else{
      carTabSpan.innerHTML = 'Scan QR of car'
    }

    scan();
  }

  const beep = (type) => {
    return new Promise((resolve) => {
      // Create an AudioContext instance
      const audioContext = new AudioContext();

      // Create an oscillator node
      const oscillator = audioContext.createOscillator();

      // Set the type of the oscillator to 'sine' wave
      oscillator.type = 'sine';

      // Set the frequency of the oscillator based on the type of sound
      switch (type) {
        case 'success':
          oscillator.frequency.setValueAtTime(1500, audioContext.currentTime);
          break;
        case 'failure':
          oscillator.frequency.setValueAtTime(500, audioContext.currentTime);
          break;
        case 'waiting':
          oscillator.frequency.setValueAtTime(1000, audioContext.currentTime);
          break;
        default:
          oscillator.frequency.setValueAtTime(1000, audioContext.currentTime);
      }

      // Connect the oscillator to the audio context's destination (e.g., speakers)
      oscillator.connect(audioContext.destination);

      // Start the oscillator
      oscillator.start();

      // Stop the oscillator after a short duration (e.g., 0.1 seconds)
      setTimeout(() => {
        oscillator.stop();
        oscillator.disconnect();
        audioContext.close();
        resolve();
      }, 100);
    });
  };

  function renderHistoryList(data) {
    historyList.innerHTML = '';

    if (!Array.isArray(data) || !data.length) {
      historyList.innerHTML          = '<li class=>There are no saved items in history.</li>';
      deleteHistoryBtn.style.display = 'none';
    } else {
      deleteHistoryBtn.style.display = 'block';
      console.log('render history', data);
      data.forEach(item => {
        const li = document.createElement('li');
        li.setAttribute('data-value', item);

        let historyItem;

        try {
          new URL(item);
          historyItem      = document.createElement('a');
          historyItem.href = item;
          historyItem.setAttribute('target', '_blank');
          historyItem.setAttribute('rel', 'noreferrer noopener');
        }
        catch (err) {
          historyItem = document.createElement('span');
        }

        historyItem.textContent = item;
        historyItem.setAttribute('title', item);

        const actionsEl     = document.createElement('div');
        actionsEl.className = 'history-modal__actions';

        const copyBtn = document.createElement('custom-clipboard-copy');
        copyBtn.title = 'Copy to clipboard';
        copyBtn.setAttribute('only-icon', '');
        copyBtn.setAttribute('value', item);
        actionsEl.appendChild(copyBtn);

        const removeBtn     = document.createElement('button');
        removeBtn.type      = 'button';
        removeBtn.className = 'history-modal__delete-action';
        removeBtn.title     = 'Remove from history';
        removeBtn.setAttribute('data-action', 'delete');
        removeBtn.innerHTML = /* html */`
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
          </svg>
        `;
        actionsEl.appendChild(removeBtn);

        li.appendChild(historyItem);
        li.appendChild(actionsEl);
        historyList.appendChild(li);
      });
    }
  }

  async function addToHistory(item) {
    const { value: settings } = await getSettings();
    console.log('addToHistory', item, settings);
    if (!item || !settings?.addToHistory) {
      return;
    }

    const { value: history = [], error: getHistoryError } = await getHistory();

    if (!getHistoryError && !history.find(h => h === item)) {
      const data = [...history, item];

      const { error: setHistoryError } = await setHistory(data);

      if (!setHistoryError) {
        renderHistoryList(data);
      }
    }
  }

  async function removeFromHistory(value) {
    if (!value) {
      return;
    }

    const { value: history = [], error: getHistoryError } = await getHistory();

    if (!getHistoryError) {
      const data = history.filter(item => item !== value);

      const { error: setHistoryError } = await setHistory(data);

      if (!setHistoryError) {
        renderHistoryList(data);
      }
    }
  }

  async function emptyHistory() {
    const { error: setHistoryError } = await setHistory([]);

    if (!setHistoryError) {
      renderHistoryList([]);
    }
  }

  const vibrate = (duration) => {
    // Check if the Vibration API is supported
    if ('vibrate' in navigator) {
      // Vibrate the phone for the specified duration
      navigator.vibrate(duration);
    } else {
      console.log('Vibration API is not supported.');
    }
  };

  function resizeScanFrame(videoEl) {
    if (!videoEl) {
      return;
    }

    const rect = videoEl.getBoundingClientRect();

    scanFrameEl.style.cssText = `width: ${ rect.width }px; height: ${ rect.height }px`;
  }

  function emptyResults(resultDialog) {
    resultDialog?.querySelector('.results__item')?.remove();
  }

  async function createResult(value, resultDialog) {
    if (!value || !resultDialog) {
      return;
    }

    emptyResults(resultDialog);

    let resultItem;

    try {
      const { value: settings } = await getSettings();

      new URL(value);
      resultItem      = document.createElement('a');
      resultItem.href = value;

      if (!settings?.openWebPageSameTab) {
        resultItem.setAttribute('target', '_blank');
        resultItem.setAttribute('rel', 'noreferrer noopener');
      }

      if (settings?.openWebPage) {
        resultItem.click();
      }
    }
    catch (err) {
      resultItem = document.createElement('span');
    }

    resultItem.className = 'results__item';
    resultItem.classList.toggle('results__item--no-barcode', value === NO_BARCODE_DETECTED);
    resultItem.textContent = value;

    resultDialog.insertBefore(resultItem, resultDialog.querySelector('.results__actions'));

    const clipboarCopyEl = resultDialog.querySelector('custom-clipboard-copy');
    const webShareEl     = resultDialog.querySelector('web-share');
    const isValidValue   = value !== NO_BARCODE_DETECTED;

    if (clipboarCopyEl) {
      clipboarCopyEl.disabled = !isValidValue;
      clipboarCopyEl.hidden   = !isValidValue;
    }

    if (webShareEl && isWebShareSupported()) {
      webShareEl.disabled = !isValidValue;
      webShareEl.hidden   = !isValidValue;

      if (isValidValue) {
        webShareEl.setAttribute('share-text', value);
      } else {
        webShareEl.removeAttribute('share-text');
      }
    }

    resultDialog.show();
  }

  function detectBarcode(source) {
    return new Promise((resolve, reject) => {
      barcodeDetector.detect(source).then(async (results) => {
        if (Array.isArray(results) && results.length > 0) {
          resolve(results[0]);

          const codes    = results;
          let wheel      = document.getElementById(activeWheel);
          let tyreSerial = 0;
          let tyreType = 'slick'

          codes.map(async (code) => {
            switch (code.format) {
              case 'qr_code':
                teamData = parseData(code.rawValue);

                if (!teamData.startNumber) {
                  log('QR code does not contain startNumber.');
                  reject({
                    message: 'QR code does not contain startNumber.'
                  });
                  await beep('failure');
                  vibrate(1000);
                  break;
                }

                wheels.forEach(item => item.classList.add('is-ready'));

                if (teamData.side) {
                  activeWheel = `wheelNumber_${ teamData.location.toLowerCase() }${ teamData.side.charAt(0).toUpperCase() + teamData.side.slice(1) } }`;
                  wheel       = document.getElementById(activeWheel);
                  wheels.forEach(item => item.classList.remove('is-active'));
                  wheel.classList.add('is-active');

                  tyreSerial = wheel.innerHTML;

                  await beep('waiting');
                  vibrate(1000);

                  setTimeout(() => scanBtn.click(), 2000);
                } else {
                  createTeam(teamData).then(response => {
                    if (response.tyres) {
                      Object.keys(response.tyres).forEach((position) => {
                        const wheel = document.getElementById(`wheelNumber_${ position }`);
                        if (wheel && response.tyres[position].tyreSerial) {
                          wheel.innerHTML = `${ response.tyres[position].tyreSerial }`;
                          wheel.classList.add('is-valid');
                        }
                      });
                    }
                  });

                  await beep('success');
                  vibrate(1000);
                }
                break;

              default:
                console.log(code);
                if (!wheel) {
                  reject({
                    message: 'Could not know which wheel that was supposed to be scanned, choose a tyre first'
                  });

                  await beep('failure');

                  resetView("Make sure to scan the QR code for the car", true);

                  break;
                }

                wheel.innerHTML = code.rawValue;
                wheel.classList.add('is-valid');
                wheel.classList.remove('is-active');

                resetView("Press each wheel to scan", false);

                await beep('success');
                vibrate(1000);
                break;
            }
          });

          if (teamData.startNumber > 0) {
            startNumber.innerHTML = teamData.startNumber;

            resetView('Scan each wheel', false);
          }

          if (!teamData.side && wheel.dataset.side) {
            teamData.side     = wheel.dataset.side;
            teamData.location = wheel.dataset.location;
          }

          if (teamData.side && teamData.startNumber > 0 && parseInt(wheel.innerHTML) > 0) {
            addWheel({ ...wheel.dataset, startNumber: teamData.startNumber, tyreSerial: wheel.innerHTML, type: tyreType }).then(response => {
              activeWheel = `wheelNumber_${ teamData.location.toLowerCase() }${ teamData.side.charAt(0).toUpperCase() + teamData.side.slice(1) }`;
              document.getElementById(activeWheel).classList.add('is-valid');
            });

            resetView('Scan next wheel', false);
          }

        } else {
          reject({
            message: 'Could not detect barcode from provided source.'
          });
        }
      }).catch(err => {
        reject(err);
      });
    });
  }

  function parseData(data) {
    const [url, startNumber, driverName, nat, teamName, vehicle, side, location] = data.split('\n');

    if (parseInt(startNumber) > 0) {
      return {
        startNumber: parseInt(startNumber),
        driverName,
        nat,
        teamName,
        vehicle,
        side,
        location
      };
    }

    return data;
  }

  async function scan() {
    log('Scanning...');

    scanInstructionsEl.hidden = false;

    try {
      let barcode = {};
      barcode     = await detectBarcode(capturePhotoVideoEl);
      clearTimeout(scanTimeout);
      //window.cancelAnimationFrame(rafId);
      emptyResults(cameraResultsEl);
      await createResult(barcode.rawValue, cameraResultsEl);
      await addToHistory(barcode.rawValue);
      scanInstructionsEl.hidden = true;
      scanBtn.hidden            = false;
      scanFrameEl.hidden        = true;
      return;
    }
    catch (err) {
      // Fail silently...
    }

    if (shouldRepeatScan && window.BarcodeDetector) {
      scanTimeout = setTimeout(scan, 200);
      //rafId = window.requestAnimationFrame(() => scan());
    }
  }

  function log(...args) {
    process.env.NODE_ENV === 'development' && console.log(...args);
  }

  const addWheel = async (data) => {
    console.log('Posting wheel data', data);
    try {
      const response = await fetch(`${ apiBase }/wheels/V8 Thunder Cars/${ data.startNumber }`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          // Add any additional headers if needed
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error('Failed to post data to API');
      }

      const responseData = await response.json();
      return responseData;
    }
    catch (error) {
      console.error('Error posting data to API:', error.message);
      throw error;
    }
  };

  const createTeam = async (data) => {
    try {
      const response = await fetch(`${ apiBase }/wheels/V8 Thunder Cars`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          // Add any additional headers if needed
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error('Failed to post data to API');
      }

      const responseData = await response.json();
      return responseData;
    }
    catch (error) {
      console.error('Error posting data to API:', error.message);
      throw error;
    }
  };

  scanBtn.addEventListener('click', () => {
    scanBtn.hidden     = true;
    scanFrameEl.hidden = false;
    emptyResults(cameraResultsEl);
    cameraResultsEl.close();

    clearTimeout(scanTimeout);
    //console.log('Waiting to scan, scanbutton clicked')
    //scanTimeout = setTimeout(() => scan(), 1000);
  });

  tabGroupEl.addEventListener('a-tab-show', debounce(evt => {
    const tabId = evt.detail.tabId;

    if (tabId === 'cameraTab') {
      shouldRepeatScan = true;

      // Get the latest instance of capture-photo element to ensure we don't use the cached one.
      const capturePhotoEl = document.querySelector('capture-photo');

      if (
        capturePhotoEl // Assumes that element exists; it might not be the case if the user is using a browser that does not support the BarcodeDetector API.
        && !capturePhotoEl.loading
        && !cameraResultsEl.querySelector('.results__item')
      ) {
        if (typeof capturePhotoEl.startVideoStream === 'function') {
          capturePhotoEl.startVideoStream();
        }

        console.log('Waiting to scan', activeWheel);
        clearTimeout(scanTimeout);
        openScanView('Scan QR code or barcode')
        scanTimeout = setTimeout(() => {
          resetView("Click on car to start", true);
        }, 6000);
      }
    }

    if (tabId === 'carTab') {
      shouldRepeatScan = false;

      if (capturePhotoEl != null && typeof capturePhotoEl.stopVideoStream === 'function') {
        capturePhotoEl.stopVideoStream();
      }

      clearTimeout(scanTimeout);
    }
  }, 250));

  resizeObserverEl.addEventListener('resize-observer:resize', () => {
    resizeScanFrame(capturePhotoEl.shadowRoot.querySelector('video'));
  });

  settingsBtn.addEventListener('click', () => {
    settingsDialog.showModal();
  });

  nextBtn.addEventListener('click', () => {
    activeWheel = '';
    carContainer.querySelectorAll('.wheel-input').forEach(item => {
      item.innerHTML = 'Click & Scan';
      item.classList.remove('is-valid');
      item.classList.remove('is-ready');
      item.classList.remove('is-invalid');
      item.classList.remove('is-active');
    });
    startNumber.innerHTML = 'X';
    resetView('Click on car to start', false);
  });

  historyBtn.addEventListener('click', () => {
    historyDialog.showModal();
  });

  carContainer.addEventListener('click', evt => {
    wheels.forEach(item => item.classList.remove('is-active'));

    if (evt.target.classList.contains('wheel-input')) {
      activeWheel = evt.target.id;
      wheels.forEach(item => item.classList.remove('is-active'));
      evt.target.classList.add('is-active');

      teamData.location = evt.target.dataset.location;
      teamData.side     = evt.target.dataset.side;
    }

    if (evt.target.classList.contains('startnumber-input') || evt.target.classList.contains('car-image') || evt.target.classList.contains('wheel-input')) {
      cameraPanel.parentElement.children[2].click();
      scanBtn.click();
    }
  });

  historyDialog.addEventListener('click', evt => {
    if (evt.target === evt.currentTarget) {
      historyDialog.close();
    }
  });

  settingsDialog.addEventListener('click', evt => {
    if (evt.target === evt.currentTarget) {
      settingsDialog.close();
    }
  });

  settingsForm.addEventListener('change', evt => {
    const settings   = {};
    const checkboxes = evt.currentTarget.querySelectorAll('input[type="checkbox"]');

    checkboxes.forEach(item => settings[item.name] = item.checked);
    setSettings(settings);
  });

  historyList.addEventListener('click', evt => {
    const target = evt.target;

    if (target.closest('[data-action="delete"]')) {
      if (window.confirm('Delete item from history?')) {
        removeFromHistory(target.closest('li').dataset.value);
      }
    }
  });

  deleteHistoryBtn.addEventListener('click', () => {
    if (window.confirm('Are you sure you want to empty history?')) {
      emptyHistory();
    }
  });
}());
